@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/* 
  Note: The styles for this gradient grid background is heavily inspired by the creator of this amazing site (https://dub.sh) – all credits go to them! 
*/

@font-face {
    font-family: Maehongson;
    src: url('../fonts/Maehongson/pk_maehongson_medium-webfont.eot');
    src: url('../fonts/Maehongson/pk_maehongson_medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Maehongson/pk_maehongson_medium-webfont.woff2') format('woff2'),
         url('../fonts/Maehongson/pk_maehongson_medium-webfont.woff') format('woff'),
         url('../fonts/Maehongson/pk_maehongson_medium-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Maehongson;
    src: url('../fonts/Maehongson/pk_maehongson-semi_bold-webfont.eot');
    src: url('../fonts/Maehongson/pk_maehongson-semi_bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Maehongson/pk_maehongson-semi_bold-webfont.woff2') format('woff2'),
         url('../fonts/Maehongson/pk_maehongson-semi_bold-webfont.woff') format('woff'),
         url('../fonts/Maehongson/pk_maehongson-semi_bold-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: 600;
}
@font-face {
    font-family: Jipatha;
    src: url(../fonts/Jipatha/Jipatha-Regular.ttf);
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    width: 10px;
}


/* Track */
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.remove-arrow {
    -moz-appearance: textfield;
}

h1,
h2,
h3,
h4,
p,
span {
    font-family: 'Montserrat', 'sans-serif';
}

div {
    z-index: 10;
}

div>* {
    z-index: 10;
}

ul>* {
    z-index: 10;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.feather-blur {
    mask: linear-gradient(to top, transparent, black 10%);
    backdrop-filter: blur(2px);
}

.feather-blur-sm {
    @media only screen and (max-width: 639px) {
        mask: linear-gradient(to top, transparent, black 5%);
        backdrop-filter: blur(2px);
    }
}

.small-scroll::-webkit-scrollbar {
    width: 3px;
    /* Set the width of the scrollbar */
    background-color: #F5F5F5;
    /* Set the background color of the scrollbar */
    position: absolute;
    left: 0;
    /* Position the scrollbar on the left of the element */
}

.small-scroll::-webkit-scrollbar-thumb {
    background-color: #535353;
    /* Set the color of the thumb */
}


/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}

[aria-label="Map"] {
    z-index: -3;
}

[aria-label="แผนที่"] {
    z-index: -3;
}

[tabindex="1"] {
    z-index: -3;
}
.main {
    width: 100vw;
    min-height: 100vh;
    position: fixed;
    display: flex;
    justify-content: center;
    padding: 120px 24px 160px 24px;
    pointer-events: none;
}

.main:before {
    background: radial-gradient(circle, rgba(2, 0, 36, 0) 0, #fafafa 100%);
    position: absolute;
    content: "";
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
}

.bg-pattern {
    /* background: rgba(131, 187, 63, .3); */
    background: #eee;
    background-image: url("https://www.toptal.com/designers/subtlepatterns/uploads/leaves.png?__cf_chl_tk=pMasEEv3B82Ushk6EyPbKIlTdKI4wwR_eLJK8tsD9kk-1699801220-0-gaNycGzND5A");

}
.gradient {
    background: rgb(131, 187, 63);
    background: linear-gradient(8deg, rgba(131, 187, 63, 1) 14%, rgba(51, 172, 202, 1) 94%);
}

.gradient-faded {
    background: rgba(131, 187, 63, .5);
    background: linear-gradient(8deg, rgba(131, 187, 63, .5) 14%, rgba(51, 172, 202, .5) 94%);
}

@media screen and (max-width: 640px) {
    .main {
        padding: 0;
    }
}

@media screen and (max-width: 760px) {
    .app {
        padding: 0;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 30px;
}


/* Hide default HTML checkbox */

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}


/* The slider */

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .2s;
    transition: .2s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .2s;
    transition: .2s;
}

input:checked+.slider {
    background-color: #32a852;
}

input:focus+.slider {
    box-shadow: 0 0 1px #32a852;
}

input:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}


/* Rounded sliders */

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}


/* Tailwind Styles */

.app {
    @apply h-full w-full
}

.map {
    @apply h-full w-full
}

.map-container {
    @apply h-full w-full
}

.list-element {
    @apply flex items-center justify-between
}

.button-gradient {
    @apply font-jipatha text-[2.4rem] w-full py-[14px] bg-gradient-to-r from-[#83bb3f] to-[#33acca] leading-[1.6rem] from-10% to-50% text-white tracking-[0.04em] bg-[position:_0%_0%] hover:bg-[position:_60%_100%] bg-[size:_200%] transition-all duration-500 active:scale-[93%]
    sm:text-[2rem] sm:py-[10px]
}

.text-gradient {
    @apply bg-gradient-to-r from-[#83bb3f] to-[#33acca] leading-[1.6rem] tracking-[0.04em] transition-all text-transparent bg-clip-text
}

.button-link {
    @apply font-jipatha text-[1.8rem] underline decoration-1 leading-[1.6rem] hover:text-gray-800 cursor-pointer
}

.heading-1 {
    @apply font-maehongson font-semibold xl:text-[1.8rem] text-[1.8rem] leading-[1.6rem] text-[#4b4b4b] sm:tracking-[0px] tracking-[0.035em] sm:!text-[1.6rem]
}

.paragraph {
    @apply font-jipatha leading-[1.8rem] w-[32ch] text-[2.2rem] xl:text-[2rem] sm:text-[1.8rem] sm:leading-[1.6rem] 
}

.info-box {
    @apply transition-all absolute bottom-[2rem] left-0 right-0 ml-auto mr-auto w-[50vw] sm:h-[70vh] h-[80vh] min-w-[21rem] max-w-[28rem] z-30 px-5 bg-white rounded-md shadow-md py-[3rem] 
}

